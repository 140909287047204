import { localStage, STAGE_NAME } from '@shared/constants';
import { ReportSettingsType } from '@shared/types/ReportTypes';

/**
 * Reads out the feature flag.
 * https://prismacode.visualstudio.com/prismacloud/_wiki/wikis/prismacloud.wiki/366/Feature-Flag-(WebApp)
 *
 * Example how to create a feature flag.
 * export const IsP3ConfigureDeviceEnabled: () => boolean = fromEnvironmentVariableOrOnStage(
 *   '$WEBAPP_P3ConfigureDeviceEnabled',
 *   localStage
 * );
 */
function fromEnvironmentVariableOrOnStage(envVariableValue: string, ...stages: string[]): boolean {
  if (envVariableValue === '1' || envVariableValue.toLowerCase() === 'true') {
    return true;
  }

  return stages.indexOf(STAGE_NAME) > -1;
}

export const GetDisabledLanguages: () => string = () => {
  if (STAGE_NAME === localStage) {
    return '';
  }

  return 'zh';
};

export const IsBrandingEnabled = (): boolean => {
  return fromEnvironmentVariableOrOnStage('true', localStage);
};

export const IsPatientAppSettingShown = (): boolean => {
  return fromEnvironmentVariableOrOnStage('true', localStage);
};

/**
 * Returns ComplianceSummary or DeviceCompliance type.
 */
export const GetComplianceType = (): ReportSettingsType => {
  if (STAGE_NAME === localStage) {
    return 'ComplianceSummary';
  }

  return 'ComplianceSummary' as ReportSettingsType;
};

export const GetStatusPageId = (): string => {
  if (STAGE_NAME === localStage) {
    return '5fmf3jg407nz';
  }

  return '5fmf3jg407nz';
};

export const IsPrismaFirmwareDownloadAllowed = (): boolean => {
  return fromEnvironmentVariableOrOnStage('true', localStage);
};

export const IsTeleSettingAllowedForStage = (): boolean => {
  if (STAGE_NAME === localStage) {
    return true;
  }
  return !fromEnvironmentVariableOrOnStage('false');
};

export const IsTrendCurvesViewerEnabledForStage = () =>
  fromEnvironmentVariableOrOnStage('true', localStage);

export const IsAnnualOverviewEnabledForStage = () =>
  fromEnvironmentVariableOrOnStage('false', localStage);

export const IsTelemonitoringSwitchingAllowedForStage = (): boolean => {
  if (STAGE_NAME === localStage) {
    return true;
  }
  return !fromEnvironmentVariableOrOnStage('false');
};

export const GetKibanaUrl: () => string = () => {
  if (STAGE_NAME === localStage) {
    return '';
  }
  return 'https://logs-dev.lmt-infra.cc/app/data-explorer/discover#';
};

export const GetKibanaIndexUnid: () => string = () => {
  if (STAGE_NAME === localStage) {
    return '';
  }
  return 'babd7d70-e7a7-11ef-8ccf-5b810c4bb269';
};

export const ShowListOfControls = () => {
  if (STAGE_NAME == localStage || STAGE_NAME.includes('today')) {
    return true;
  }
};

window.GetComplianceType = GetComplianceType;
